.new-topo{
	background: #F6E8C6;
	border-bottom:12px solid #855A25;
	position: relative;
	z-index: 990;

	.content{
		.brand{
			text-align:center;
			padding-top: 20px;
			padding-bottom: 20px;

			@include media-breakpoint-up(lg){
				display: none;
			}
		}

		@include media-breakpoint-up(md){
			padding-left: 15px;
			padding-right: 15px;
			margin-right: auto;
			margin-left: auto;

			.nav-controls{
				border-radius:0 0 10px 10px;
				overflow: hidden;
			}
		}

		@include media-breakpoint-only(md){
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;

			.brand{
				width: 100%;
			}
		}

		@include media-breakpoint-up(lg){
			width: map-get($container-max-widths, lg);
			display: flex;
			justify-content: space-between;
			margin-bottom: 60px;
		}

		@include media-breakpoint-up(xl){
			width: map-get($container-max-widths, xl);
			display: flex;
			justify-content: flex-end;
			margin-bottom: 60px;
		}
	}

	.btn{
		border-radius:0;
		font-family: 'open_sansbold';
	}

	.btn-toggle-menu{
		@include button-variant(#855A25,#855A25);

		@include media-breakpoint-up(lg){
			display: none;
		}
	}
	
	.nav-controls{
		background: #855A25;
		color: #FFF;

		@include media-breakpoint-down(md){
			display: flex;
			justify-content: space-between;
		}
		
		@include media-breakpoint-up(md){
			border-radius:0 0 10px 10px;
		}

		@include media-breakpoint-up(lg){
			min-width: 290px;
			margin-right: 151px;
		}
	}

	.client-area{
		padding:10px;
		display: flex;
		align-items: center;
		font-family: 'open_sansbold';
		font-size: 14px;

		span{
			display: block;
			padding:0 10px;
		}

		.btn{
			font-family: 'open_sansbold';
			font-size: 13px;
			padding: 8px 20px;
		}
	}

	.fones-contato{
		display: flex;
		flex-wrap: wrap;
		font-family: 'open_sansbold';

		@include media-breakpoint-up(md){
			border-radius:0 0 10px 10px;
			background: #855A25;
			color: #FFF;
		}

		@include media-breakpoint-up(xl){
			min-width: 370px;
		}
		.media{
			padding: 10px;
			font-size: 14px;

			.icon{
				margin-right: 10px;
			}

			.title{

				&:after{
					display: none;
				}
			}
		}
	}
}

.nav-content{
	p{
		margin-bottom: 0 !important;
	}

	@include media-breakpoint-up(lg){
		width: map-get($container-max-widths, lg);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: -12px;

		.brand{
			text-align: center;
			margin-bottom: 20px;
		}
	}

	@include media-breakpoint-up(xl){
		width: map-get($container-max-widths, xl);
		display: flex;
		justify-content: space-between;

		.brand{
			min-width: 223px;
			width: 223px;
			position: relative;

			img{
				position: absolute;
				left: 0;
				bottom: -55px;
			}
		}
	}

	@include media-breakpoint-down(md){
		.brand{
			padding: 30px 15px 20px 15px;
			text-align: center;
		}
	}
}


.navigation-menu{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	font-size: 14px;
	font-family: 'open_sansbold';

	a{
		display: block;
	}


	@include media-breakpoint-up(lg){
		display: flex;
		justify-content: space-between;

		a{
			padding:10px 10px 30px 10px;
			white-space: nowrap;
		}

		.active a,
		a:hover{
			color:#FFF !important;
			background: #94302D;
			border-radius:7px 7px 0 0;
		}
	}

	@include media-breakpoint-down(md){
		a{
			padding: 10px 15px;
		}

		.active a,
		a:hover{
			color:#FFF !important;
			background: #94302D;
		}
	}
}

.navigation-nav{
	@include media-breakpoint-down(md){
		position: fixed;
		top: 0;
		left: -250px;
		width: 250px;
		height: 100%;
		z-index: 999;
		background: #F6E8C6;
		overflow: auto;
		transition: left 0.6s linear;

		&.active{
			left: 0;
		}
	}
}

.bg-new-navigation{
	position: fixed;
	z-index: 998;
	background: rgba(#000,0.8);
	width: 100%;
	height:100%;
	display: none;
	top:0;
	left:0;

	@include media-breakpoint-down(md){
		&.active{
			display: block;
		}
	}
}