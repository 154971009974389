.carousel{
	@include media-breakpoint-up(xl){
		.carousel-control-prev{
			left: -23px;
		}
		.carousel-control-next{
			right: -23px;
		}
	}
}


.boleto-fixed{
	position: fixed;
	right: 10px;
	top:70px;
	z-index: 1578;
	filter: drop-shadow(0 0 5px #000);

	@include media-breakpoint-down(xs){
		display: none;
	}
}

.plano{
	position: relative;
	width: 370px;
	max-width: 100%;
	margin-left:auto;
	margin-right:auto;
	padding-top: 56px;
	text-align: center;
	margin-bottom: 50px;
	
	.circle-photo{
		width: 112px;
		height: 112px;
		border:3px solid #FFF;
		background-position: center center;
		background-size: cover;
		box-shadow:0 0 4px rgba(#000,0.4);
		border-radius: 100%;
		position: absolute;
		left:50%;
		top:-56px;
		transform: translateX(-50%);
		z-index: 3;
		background-color: #ddd;
	}

	lazyimage{
		display:block;
	}

	.image-area{
		position: relative;
		transition: all 0.3s linear;

		&:before{
			content:'';
			z-index: 1;
			position: absolute;
			width: 100%;
			height: 100%;
			left:0;
			top:0;
			background: rgba(#805B11, 0.7)
		}

		&:after{
			content:'';
			display: block;
			position:absolute;
			left:7px;
			top:7px;
			width: calc(100% - 14px);
			height: calc(100% - 14px);
			border:1px solid #FFF;
			z-index: 2;
		}
	}

	.principal-photo{
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 200px;
	}

	&:hover .image-area{
		$scale: (390 / 360);
		transform: scale(#{$scale});

		.principal-photo{
			box-shadow: 0 0 4px rgba(#000,0.8)
		}
	}

	.caption{
		position:absolute;
		padding: 20px;
		width: 100%;
		bottom: 0;
		z-index: 4;
		color: #FFF;
		font-family: 'open_sansbold';
		line-height:1;
	}

	.plano-title{
		font-size: 20px;
	}

	.name{
		font-size: 28px;
	}

	.tier{
		font-size: 28px;
	}

	.more-details{
		padding-top: 15px;
		text-align: center;
		color: #B19120;
		font-family: 'open_sansbold';
	}

	&.planos-unit{
		.plano-title{
			font-size: 28px;
		}

		.nome,
		.tier{
			font-size: 20px;
		}
	}

	&.planos-gigabiga{
		width: 100%;
		margin-bottom:30px;
	}
}

.carousel {
	.card-servicos{
		margin-top:40px;
	}
}

.plano.servico{
	width: 230px;
}

.capa{
	overflow: hidden;
}

.chmd-assistencia{
	padding-top: 60px;
	padding-bottom: 60px;
	background: #EEE;

	.h1,
	h1,
	h2,.h2,
	h3,.h3{
		font-family: 'poppins-extrabold';
	}
}

.btn-light-brown{
	background: #B57A30;
	color: #FFF;
	white-space: normal;

	&:hover{
		background: darken(#B57A30, 10%);
		color: #FFF;
	}
}

.btn-light-orange{
	@include button-variant(#E9B852, #E9B852);
	color: #FFF !important;
}

.row-planos{
	.col-lg-3{
		display:flex;

		.box-planos{
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.description-box .text-area{
			flex-grow:1;
			line-height: 1.4;
			max-height: (1.4em * 4);
			overflow: hidden;

			&.clamp{
				position:relative;

				&:after{
					content:'...';
					display: block;
					padding:0 0.3em;
					height: 1.4em;
					background:#FFF;
					right:0;
					bottom: 0;
					position: absolute;
					text-align: right;
				}
			}

			@supports (-webkit-line-clamp: 1) {
				display: -webkit-box;
				  -webkit-line-clamp: 4;
				  -webkit-box-orient: vertical;
			}

		}
	}
}

.text-beneficios{
	.h1{
		color: #94302D;
		font-family: 'open_sansbold';
		margin-bottom: 20px;
	}
}

.box-contato-duvidas{
	font-family: 'open_sansregular';
	width:570px;
	max-width: 100%;

	$bg:#94302D;

	.box-contato-duvidas-text{
		width: 400px;
		max-width:100%;
		margin-left: auto;
		margin-right: auto;
		background: $bg;
		padding: 15px;
		border-radius: 7px;
		color: #FFF;
		text-align:center;
	}
}

.plano-only{
	width: 555px;

	.principal-photo{
		position: relative;
		overflow: hidden;
		padding-bottom: percentage(275 / 555);
	}

	lazyimage{
		position: absolute;
		width: 100%;
		height:100%;
		display: flex;
		align-items:center;
		justify-content:center;
		top:0;
		left:0;
	}
}

.obtuario{
	padding: 40px;
	background: #eee;

	.bg-ocre{
		background: darken(#F4E9C4, 10%)
	}
}

.btn-ocre {
	@include button-variant(#AE7D17, #AE7D17);
	color: #FFF;

	&:hover{
		color: #FFF;
	}
}

.btn-expanded{
	padding: 12px 24px;
}

.btn-pill{
	border-radius: 30px;
}

.btn-wine {
	@include button-variant(#94302D, #94302D);
	color: #FFF;

	&:hover{
		color: #FFF;
	}
}

#servico-detalhes{
	.owl-nav{
		text-align: center;
	}

	.owl-nav .disabled{
		opacity: 0;
		cursor: default;
	}

	.owl-next{
		position: relative;
		width: 2em;
		height: 2em;
		border-radius:100%;
		overflow: hidden;
		@extend .btn-wine, .fa, .fa-chevron-right;
		text-indent: -999px;
		display: inline-block;
		margin: 10px;
		cursor:pointer;

		&:before{
			position:absolute;
			text-indent: 0;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%)
		}
	}
	.owl-prev{
		position: relative;
		width: 2em;
		height: 2em;
		border-radius:100%;
		overflow: hidden;
		@extend .btn-wine, .fa, .fa-chevron-left;
		text-indent: -999px;
		cursor:pointer;
		display: inline-block;
		margin: 10px;

		&:before{
			position:absolute;
			text-indent: 0;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%)
		}
	}
}