.icon{
	background-image: url('#{$imgs}/sprite.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 590px 673px;
}

.arrow-left{
	width: 23px;
	min-width: 23px;
	height: 52px;
	background-position: -516px -97px;
}
.arrow-right{
	width: 23px;
	min-width: 23px;
	height: 52px;
	background-position: -516px -159px;
}
.check{
	width: 22px;
	min-width: 22px;
	height: 24px;
	background-position: -564px -34px;
}
.clock-green{
	width: 20px;
	min-width: 20px;
	height: 20px;
	background-position: -564px -173px;
}
.clock{
	width: 15px;
	min-width: 15px;
	height: 14px;
	background-position: -564px -372px;
}
.contrate-footer{
	width: 26px;
	min-width: 26px;
	height: 26px;
	background-position: -516px -488px;
}
.facebook-active{
	width: 29px;
	min-width: 29px;
	height: 28px;
	background-position: -516px -260px;
}
.facebook-white{
	width: 13px;
	min-width: 13px;
	height: 23px;
	background-position: -564px -203px;
}
.facebook{
	width: 29px;
	min-width: 29px;
	height: 28px;
	background-position: -516px -298px;
}
.futuros-nav-next{
	width: 26px;
	min-width: 26px;
	height: 24px;
	background-position: -516px -632px;
}
.futuros-nav-prev{
	width: 26px;
	min-width: 26px;
	height: 24px;
	background-position: -564px 0px;
}
.house{
	width: 16px;
	min-width: 16px;
	height: 14px;
	background-position: -564px -348px;
}
.info{
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -564px -298px;
}
.instagran-active{
	width: 29px;
	min-width: 29px;
	height: 28px;
	background-position: -516px -336px;
}
.instagran-white{
	width: 21px;
	min-width: 21px;
	height: 21px;
	background-position: -564px -142px;
}
.instagran{
	width: 29px;
	min-width: 29px;
	height: 28px;
	background-position: -516px -374px;
}
.mail-green{
	width: 18px;
	min-width: 18px;
	height: 14px;
	background-position: -564px -324px;
}
.mail{
	width: 26px;
	min-width: 26px;
	height: 26px;
	background-position: -516px -524px;
}
.nav-next{
	width: 16px;
	min-width: 16px;
	height: 27px;
	background-position: -564px -68px;
}
.nav-prev{
	width: 16px;
	min-width: 16px;
	height: 27px;
	background-position: -564px -105px;
}
.novidades-nav-next{
	width: 12px;
	min-width: 12px;
	height: 21px;
	background-position: -564px -236px;
}
.novidades-nav-prev{
	width: 12px;
	min-width: 12px;
	height: 21px;
	background-position: -564px -267px;
}
.parceiros-img{
	width: 506px;
	min-width: 506px;
	height: 673px;
	background-position: 0px 0px;
}
.phone{
	width: 26px;
	min-width: 26px;
	height: 26px;
	background-position: -516px -560px;
}
.search{
	width: 36px;
	min-width: 36px;
	height: 38px;
	background-position: -516px -49px;
}
.twitter-active{
	width: 29px;
	min-width: 29px;
	height: 28px;
	background-position: -516px -412px;
}
.twitter{
	width: 29px;
	min-width: 29px;
	height: 28px;
	background-position: -516px -450px;
}
.user{
	width: 26px;
	min-width: 26px;
	height: 26px;
	background-position: -516px -596px;
}
.whatsapp-footer{
	width: 29px;
	min-width: 29px;
	height: 29px;
	background-position: -516px -221px;
}
.whatsapp{
	width: 38px;
	min-width: 38px;
	height: 39px;
	background-position: -516px 0px;
}
