
a{
	text-decoration: none!important;
}

a:hover{
	color: inherit;
}

label{
	margin-bottom:5px;
}
.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
    @extend .img-fluid;
}

//SIZES
	@for $i from 1 through 200{
		.size#{$i}{
			font-size: #{$i}px;
		}
	}

//FIM SIZES

//WIDTHS
	@for $i from 1 through 1000{
		.width#{$i}{
			width: #{$i}px;
		}

		.max-width#{$i}{
			max-width: #{$i}px;
		}

		.min-width#{$i}{
			min-width: #{$i}px;
		}
	}

	@for $i from 1 through 200{
		.width#{$i}p{
			width: percentage($i/100);
		}

		.max-width#{$i}p{
			max-width: percentage($i/100);
		}
	}
//FIM WIDTHS

//HEIGHTS
	.height-auto{
		height: auto;
	}

	@for $i from 1 through 920{
		.height#{$i}{
			height: #{$i}px;
		}
		.line-height#{$i}{
			line-height: #{$i}px;
		}

		.min-height#{$i}{
			min-height: #{$i}px;
		}
	}

	@for $i from 1 through 200{
		.height#{$i}p{
			height: percentage($i/100);
		}

		.max-height#{$i}p{
			max-height: percentage($i/100);
		}
	}
//FIM HEIGHTS
//TRANSFORMS
	.transform-rotate12{
		transform: rotate(12deg);
	}
//FIM TRANSFORMS
//MARGIN
	//.form-group{
		//margin-bottom: 0.25rem!important;
	//}

	.margin-auto{
		margin-left: auto;
		margin-right: auto;
	}
	@for $i from -200 through 920{
		.margin#{$i}{
			margin: #{$i}px;
		}

		.margin-left#{$i}{
			margin-left: #{$i}px;
		}

		.margin-right#{$i}{
			margin-right: #{$i}px;
		}

		.margin-top#{$i}{
			margin-top: #{$i}px;
		}

		.margin-bottom#{$i}{
			margin-bottom: #{$i}px!important;
		}

		.margin-tb#{$i}{
			margin-top: #{$i}px;
			margin-bottom: #{$i}px;
		}

		.margin-side#{$i}{
			margin-left: #{$i}px;
			margin-right: #{$i}px;
		}
	}

	@for $i from 1 through 100{
		.margin#{$i}p{
			margin: percentage($i/100);
		}

		.margin-left#{$i}p{
			margin-left: percentage($i/100);
		}

		.margin-right#{$i}p{
			margin-right: percentage($i/100);
		}

		.margin-top#{$i}p{
			margin-top: percentage($i/100);
		}

		.margin-bottom#{$i}p{
			margin-bottom: percentage($i/100);
		}

		.margin-tb#{$i}p{
			margin-top: percentage($i/100);
			margin-bottom: percentage($i/100);
		}

		.margin-side#{$i}p{
			margin-left: percentage($i/100);
			margin-right: percentage($i/100);
		}
	} 
//FIM MARGIN

//PAGINAÇÃO
	.paginacao .active,
	.paginacao a:hover{
		background-color: #E9B852;
	}

	.paginacao .active, 
	.paginacao *:hover{
		background-color: #E9B852;
	}
//FIM PAGINAÇÃO

//PADDING
	@for $i from 0 through 920{
		.padding#{$i}{
			padding: #{$i}px;
		}

		.padding-left#{$i}{
			padding-left: #{$i}px;
		}

		.padding-right#{$i}{
			padding-right: #{$i}px;
		}

		.padding-top#{$i}{
			padding-top: #{$i}px;
		}

		.padding-bottom#{$i}{
			padding-bottom: #{$i}px;
		}

		.padding-tb#{$i}{
			padding-top: #{$i}px;
			padding-bottom: #{$i}px;
		}

		.padding-side#{$i}{
			padding-left: #{$i}px;
			padding-right: #{$i}px;
		}
	}

	@for $i from -100 through 100{
		.padding#{$i}p{
			padding: percentage($i/100);
		}

		.padding-left#{$i}p{
			padding-left: percentage($i/100);
		}

		.padding-right#{$i}p{
			padding-right: percentage($i/100);
		}

		.padding-top#{$i}p{
			padding-top: percentage($i/100);
		}

		.padding-bottom#{$i}p{
			padding-bottom: percentage($i/100);
		}

		.padding-tb#{$i}p{
			padding-top: percentage($i/100);
			padding-bottom: percentage($i/100);
		}

		.padding-side#{$i}p{
			padding-left: percentage($i/100);
			padding-right: percentage($i/100);
		}
	}
//PADDING

//TOP - BOTTOM - LEFT - RIGHT
	@for $i from -100 through 920{
		.left#{$i}{
			left: #{$i}px;
		}

		.right#{$i}{
			right: #{$i}px;
		}

		.top#{$i}{
			top: #{$i}px;
		}

		.bottom#{$i}{
			bottom: #{$i}px;
		}
	}

	@for $i from 1 through 100{
		.left#{$i}p{
			left: percentage($i/100);
		}

		.right#{$i}p{
			right: percentage($i/100);
		}

		.top#{$i}p{
			top: percentage($i/100);
		}

		.bottom#{$i}p{
			bottom: percentage($i/100);
		}
	}
//FIM TOP

//BORDER
	.border-radius-bottom10{
		border-radius:0 0 10px 10px;
	}
	@for $i from 0 through 100{
		.border-radius#{$i}p{
			border-radius: percentage($i/100);
		}
	}
	@for $i from 0 through 50{
		.border-white#{$i}{
			border: #FFFFFF solid #{$i}px;
		}

		.border-white-left#{$i}{
			border-left: #FFFFFF solid #{$i}px;
		}

		.border-white-right#{$i}{
			border-right: #FFFFFF solid #{$i}px;
		}

		.border-white-top#{$i}{
			border-top: #FFFFFF solid #{$i}px;
		}

		.border-white-bottom#{$i}{
			border-bottom: #FFFFFF solid #{$i}px!important;
		}

		.border-grayE#{$i}{
			border: #EEEEEE solid #{$i}px;
		}

		.border-grayE-left#{$i}{
			border-left: #EEEEEE solid #{$i}px;
		}

		.border-grayE-right#{$i}{
			border-right: #EEEEEE solid #{$i}px;
		}

		.border-grayE-top#{$i}{
			border-top: #EEEEEE solid #{$i}px;
		}

		.border-grayE-bottom#{$i}{
			border-bottom: #EEEEEE solid #{$i}px;
		}

		.border-grayD#{$i}{
			border: #DDDDDD solid #{$i}px;
		}

		.border-grayD-left#{$i}{
			border-left: #{$i}px solid #DDDDDD ;
		}

		.border-grayD-right#{$i}{
			border-right: #DDDDDD solid #{$i}px;
		}

		.border-grayD-top#{$i}{
			border-top: #DDDDDD solid #{$i}px;
		}

		.border-grayD-bottom#{$i}{
			border-bottom: #DDDDDD solid #{$i}px;
		}

		.border-grayD-side#{$i}{
			border-left: #DDDDDD solid #{$i}px;
			border-right: #DDDDDD solid #{$i}px;
		}

		.border-grayC#{$i}{
			border: #CCCCCC solid #{$i}px;
		}

		.border-grayC-left#{$i}{
			border-left: #CCCCCC solid #{$i}px;
		}

		.border-grayC-right#{$i}{
			border-right: #CCCCCC solid #{$i}px;
		}

		.border-grayC-top#{$i}{
			border-top: #CCCCCC solid #{$i}px;
		}

		.border-grayC-bottom#{$i}{
			border-bottom: #CCCCCC solid #{$i}px;
		}

		.border-grayB#{$i}{
			border: #BBBBBB solid #{$i}px;
		}

		.border-grayB-left#{$i}{
			border-left: #BBBBBB solid #{$i}px;
		}

		.border-grayB-right#{$i}{
			border-right: #BBBBBB solid #{$i}px;
		}

		.border-grayB-top#{$i}{
			border-top: #BBBBBB solid #{$i}px;
		}

		.border-grayB-bottom#{$i}{
			border-bottom: #BBBBBB solid #{$i}px;
		}

		.border-grayA#{$i}{
			border: #AAAAAA solid #{$i}px;
		}

		.border-grayA-left#{$i}{
			border-left: #AAAAAA solid #{$i}px;
		}

		.border-grayA-right#{$i}{
			border-right: #AAAAAA solid #{$i}px;
		}

		.border-grayA-top#{$i}{
			border-top: #AAAAAA solid #{$i}px;
		}

		.border-grayA-bottom#{$i}{
			border-bottom: #AAAAAA solid #{$i}px;
		}

		.border-gray9#{$i}{
			border: #999999 solid #{$i}px;
		}

		.border-gray9-left#{$i}{
			border-left: #999999 solid #{$i}px;
		}

		.border-gray9-right#{$i}{
			border-right: #999999 solid #{$i}px;
		}

		.border-gray9-top#{$i}{
			border-top: #999999 solid #{$i}px;
		}

		.border-gray9-bottom#{$i}{
			border-bottom: #999999 solid #{$i}px;
		}

		.border-gray8#{$i}{
			border: #888888 solid #{$i}px;
		}

		.border-gray8-left#{$i}{
			border-left: #888888 solid #{$i}px;
		}

		.border-gray8-right#{$i}{
			border-right: #888888 solid #{$i}px;
		}

		.border-gray8-top#{$i}{
			border-top: #888888 solid #{$i}px;
		}

		.border-gray8-bottom#{$i}{
			border-bottom: #888888 solid #{$i}px;
		}

		.border-gray7#{$i}{
			border: #777777 solid #{$i}px;
		}

		.border-gray7-left#{$i}{
			border-left: #777777 solid #{$i}px;
		}

		.border-gray7-right#{$i}{
			border-right: #777777 solid #{$i}px;
		}

		.border-gray7-top#{$i}{
			border-top: #777777 solid #{$i}px;
		}

		.border-gray7-bottom#{$i}{
			border-bottom: #777777 solid #{$i}px;
		}

		.border-gray6#{$i}{
			border: #666666 solid #{$i}px;
		}

		.border-gray6-left#{$i}{
			border-left: #666666 solid #{$i}px;
		}

		.border-gray6-right#{$i}{
			border-right: #666666 solid #{$i}px;
		}

		.border-gray6-top#{$i}{
			border-top: #666666 solid #{$i}px;
		}

		.border-gray6-bottom#{$i}{
			border-bottom: #666666 solid #{$i}px;
		}

		.border-gray5#{$i}{
			border: #555555 solid #{$i}px;
		}

		.border-gray5-left#{$i}{
			border-left: #555555 solid #{$i}px;
		}

		.border-gray5-right#{$i}{
			border-right: #555555 solid #{$i}px;
		}

		.border-gray5-top#{$i}{
			border-top: #555555 solid #{$i}px;
		}

		.border-gray5-bottom#{$i}{
			border-bottom: #555555 solid #{$i}px;
		}

		.border-gray4#{$i}{
			border: #444444 solid #{$i}px;
		}

		.border-gray4-left#{$i}{
			border-left: #444444 solid #{$i}px;
		}

		.border-gray4-right#{$i}{
			border-right: #444444 solid #{$i}px;
		}

		.border-gray4-top#{$i}{
			border-top: #444444 solid #{$i}px;
		}

		.border-gray4-bottom#{$i}{
			border-bottom: #444444 solid #{$i}px;
		}

		.border-gray3#{$i}{
			border: #333333 solid #{$i}px;
		}

		.border-gray3-left#{$i}{
			border-left: #333333 solid #{$i}px;
		}

		.border-gray3-right#{$i}{
			border-right: #333333 solid #{$i}px;
		}

		.border-gray3-top#{$i}{
			border-top: #333333 solid #{$i}px;
		}

		.border-gray3-bottom#{$i}{
			border-bottom: #333333 solid #{$i}px;
		}

		.border-gray2#{$i}{
			border: #222222 solid #{$i}px;
		}

		.border-gray2-left#{$i}{
			border-left: #222222 solid #{$i}px;
		}

		.border-gray2-right#{$i}{
			border-right: #222222 solid #{$i}px;
		}

		.border-gray2-top#{$i}{
			border-top: #222222 solid #{$i}px;
		}

		.border-gray2-bottom#{$i}{
			border-bottom: #222222 solid #{$i}px;
		}

		.border-gray1#{$i}{
			border: #111111 solid #{$i}px;
		}

		.border-gray1-left#{$i}{
			border-left: #111111 solid #{$i}px;
		}

		.border-gray1-right#{$i}{
			border-right: #111111 solid #{$i}px;
		}

		.border-gray1-top#{$i}{
			border-top: #111111 solid #{$i}px;
		}

		.border-gray1-bottom#{$i}{
			border-bottom: #111111 solid #{$i}px;
		}

		.border-black#{$i}{
			border: #000000 solid #{$i}px;
		}

		.border-black-left#{$i}{
			border-left: #000000 solid #{$i}px;
		}

		.border-black-right#{$i}{
			border-right: #000000 solid #{$i}px;
		}

		.border-black-top#{$i}{
			border-top: #000000 solid #{$i}px;
		}

		.border-black-bottom#{$i}{
			border-bottom: #000000 solid #{$i}px;
		}

		.border-ocre#{$i}{
			border: #F4E9C4 solid #{$i}px;
		}

		.border-ocre-left#{$i}{
			border-left: #F4E9C4 solid #{$i}px;
		}

		.border-ocre-right#{$i}{
			border-right: #F4E9C4 solid #{$i}px;
		}

		.border-ocre-top#{$i}{
			border-top: #F4E9C4 solid #{$i}px;
		}

		.border-ocre-bottom#{$i}{
			border-bottom: #F4E9C4 solid #{$i}px;
		}

		.border-light-brown#{$i}{
			border: #805B11 solid #{$i}px;
		}

		.border-light-brown-left#{$i}{
			border-left: #805B11 solid #{$i}px;
		}

		.border-light-brown-right#{$i}{
			border-right: #805B11 solid #{$i}px;
		}

		.border-light-brown-top#{$i}{
			border-top: #805B11 solid #{$i}px;
		}

		.border-light-brown-bottom#{$i}{
			border-bottom: #805B11 solid #{$i}px;
		}

		.border-green#{$i}{
			border: #88C54E solid #{$i}px;
		}

		.border-green-left#{$i}{
			border-left: #88C54E solid #{$i}px;
		}

		.border-green-right#{$i}{
			border-right: #88C54E solid #{$i}px;
		}

		.border-green-top#{$i}{
			border-top: #88C54E solid #{$i}px;
		}

		.border-green-bottom#{$i}{
			border-bottom: #88C54E solid #{$i}px;
		}

		.border-blue#{$i}{
			border: #00AFEF solid #{$i}px;
		}

		.border-blue-left#{$i}{
			border-left: #00AFEF solid #{$i}px;
		}

		.border-blue-right#{$i}{
			border-right: #00AFEF solid #{$i}px;
		}

		.border-blue-top#{$i}{
			border-top: #00AFEF solid #{$i}px;
		}

		.border-blue-bottom#{$i}{
			border-bottom: #00AFEF solid #{$i}px;
		}


		.border-baby-green#{$i}{
			border: #5EA7AD solid #{$i}px;
		}

		.border-baby-green-left#{$i}{
			border-left: #5EA7AD solid #{$i}px;
		}

		.border-baby-green-right#{$i}{
			border-right: #5EA7AD solid #{$i}px;
		}

		.border-baby-green-top#{$i}{
			border-top: #5EA7AD solid #{$i}px;
		}

		.border-baby-green-bottom#{$i}{
			border-bottom: #5EA7AD solid #{$i}px;
		}

		.border-radius#{$i}{
			border-radius: #{$i}px;
		}
	}
	.border0{
		border: 0px;
	}

	.border-right-radius18{
		border-radius: 0 18px 18px 0;
	}

	.border-left-radius18{
		border-radius: 18px 0 0 18px;
	}
//BORDER
.text-left .paginacao{
	text-align: left!important;
}

//ICON
.icon.facebook:hover{
	@extend .facebook-active;
}

.icon.facebook:hover{
	@extend .facebook-active;
}

.icon.instagran:hover{
	@extend .instagran-active;
}

.icon.twitter:hover{
	@extend .twitter-active;
}
//FIM ICON
//PLACEHOLDERS
.placeholder-white{
	&::placeholder {
	    color: white;
	    opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	   color: white;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	   color: white;
	}
}
//FIM PLACEHOLDERS

//BACKGROUNDS
	.bg-brown{
		background-color: #805B11;
	}

	.bg-caramelo{
		background-color: #AE7D17;
	}

	.hover-bg-caramelo:hover{
		background-color: #AE7D17;
		color: #ffffff;
	}

	.bg-ocre{
		background-color: #F4E9C4;
	}

	.bg-yellow{
		background-color: #E9B852;
	}

	.bg-green-opacity{
		background-color: rgba(#88C54E, .6);
	}

	.bg-green{
		background-color: #88C54E;
	}

	.bg-white-opacity{
		background-color: rgba(255,255,255,.15);
	}

	.bg-brown-opacity{
		background-color: rgba(#805B11,.7);
	}

	.bg-transparent{
		background-color: transparent;
	}

	.bg-white{
		background-color: #FFFFFF;
	}

	.bg-grayF4{
		background-color: #F4F4F4;
	}

	.bg-grayE{
		background-color: #EEEEEE;
	}

	.bg-grayD{
		background-color: #DDDDDD;
	}

	.bg-grayC{
		background-color: #CCCCCC;
	}

	.bg-grayB{
		background-color: #BBBBBB;
	}

	.bg-grayA{
		background-color: #AAAAAA;
	}

	.bg-gray9{
		background-color: #999999;
	}

	.bg-gray8{
		background-color: #888888;
	}

	.bg-gray7{
		background-color: #777777;
	}

	.bg-gray6{
		background-color: #666666;
	}

	.bg-gray5{
		background-color: #555555;
	}

	.bg-gray4{
		background-color: #444444;
	}

	.bg-gray3{
		background-color: #333333;
	}

	.bg-gray2{
		background-color: #222222;
	}

	.bg-gray1{
		background-color: #111111;
	}

	.bg-black{
		background-color: #000000;
	}

	.bg-dark-red{
		background-color: #94302D;
	}

	.bg-blue{
		background-color: #00AEF2;
	}

	.bg-dark-blue{
		background-color: #002846;
	}

	.bg-orange{
		background-color: #E9B852;
	}

	.bg-dark-orange{
		background-color: #E54C1A;
	}

	.bg-light-purple{
		background-color: #8484FB;
	}

	.bg-yellow-opacity{
		background-color: rgba(141, 105,16,.6);
	}

	.bg-old-gold{
		background-color: #bc843f;
	}

	.hover-bg-green:hover{
		background-color: #88C54E!important;
	}

	.bg-ocre{
		background-color: #F4E9C4;
	}

	.bg-footer{
		background-image: url(../images/estrutural/bg-footer.png);
		background-repeat: no-repeat;
		background-position: bottom center;
	}

	.bg-detalhe-footer{
		background-image: url(../images/estrutural/detalhe-footer.png);
		background-position: bottom center;
		background-repeat: norepeat
	}

	.bg-home-produtos{
		background-image: url(../images/estrutural/bg-home-produtos.jpg);
		background-repeat: no-repeat;
	}

	.bg-detalhe-title{
		background-image: url(../images/estrutural/detalhe-title.png);
		background-position: center center;
		background-repeat: no-repeat;
	}

	.bg-detalhe-title2{
		background-image: url(../images/estrutural/detalhe-title2.png);
		background-position: center center;
		background-repeat: no-repeat;
	}

	.bg-btn{
		background-image: url(../images/estrutural/bg-btn.jpg);
		background-size: cover;
		background-repeat: no-repeat;
	}

	.bg-home-especialidades{
		background-image: url(../images/estrutural/bg-especialidades.png);
		background-position-x: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	.bg-textura-contato-home{
		background-image: url(../images/estrutural/bg-textura-contato-home.jpg);
		background-size: cover;
		background-position-x: center;
	}

	.hover-bg-dark-orange:hover{
		background-color: #E54C1A;
	}

	.hover-bg-red:hover{
		background-color: #ff0000
	}

	.bg-novidades{
		background-image: url(../images/estrutural/bg-novidades.jpg);

		background-position-x: center;
	}

	.bg-detalhe-artigo{
		background-image: url(../images/estrutural/bg-data-artigo.png);
		background-repeat: no-repeat;
		background-position-x: center;
	}

	.bg-menu{
		background-image: url(../images/estrutural/bg-menu.png);
		background-repeat: no-repeat;
		background-position-x: center;
	}

	.bg-newsletter{
		background-image: url(../images/estrutural/bg-newsletter.png);
		background-repeat: no-repeat;
		background-position-x: center;
	}

	.bg-futuros{
		background-image: url(../images/estrutural/bg-futuros.jpg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    background-attachment: fixed;
	    background-size: cover;
	}

	.bg-parceiros-detalhe{
		background-image: url(../images/estrutural/bg-futuros.jpg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    background-attachment: fixed;
	    background-size: cover;
	    width: 100%;
	    height: 240px;
	    position: absolute;
	    bottom: 100%;
	    -webkit-mask: url(../images/estrutural/padrao-convexo.png) bottom center;
	}

	.bg-servicos{
		background-image: url(../images/estrutural/bg-servicos.jpg);
		background-position: center;
		background-size: cover;
	}
//FIM BACKGROUNDS

//ICONS
	.card .icon-gray{
		opacity: 1;
	}
	.card:hover .icon-gray{
		opacity: 0.5;
	}

	.icon-gray{
		opacity: 0.5;
	}

	.info-area{
		transform: translateX(-50%);
	}

	.icon.info:hover .info-area{
		display: block;
	}
//FIM ICONS

//COLORS
	.white{
		color: #FFFFFF!important;
	}

	.hover-white:hover{
		color: #FFFFFF!important;
	}

	.hover-red:hover{
		color: #EF263F;
	}

	.grayE{
		color: #EEEEEE;
	}

	.grayD{
		color: #DDDDDD;
	}

	.grayC{
		color: #CCCCCC;
	}

	.grayB{
		color: #BBBBBB;
	}

	.grayA{
		color: #AAAAAA;
	}

	.gray9{
		color: #999999;
	}

	.gray8{
		color: #888888;
	}

	.gray7{
		color: #777777;
	}

	.gray6{
		color: #666666!important;
	}

	.gray5{
		color: #555555;
	}

	.gray4{
		color: #444444;
	}

	.gray3,
	.gray3 a,
	.gray3:hover,
	.gray3:hover a{
		color: #333333;
	}

	.gray2{
		color: #222222;
	}

	.hover-gray2:hover{
		color: #222222;
	}

	.gray1{
		color: #111111;
	}

	a.gray1:hover{
		color: #111111;
	}

	.black{
		color: #000000;
	}

	a.black:hover{
		color: #000000;
	}

	.brown{
		color: #46390C;
	}

	.brown2{
		color: #7F5A11;
	}

	.light-brown{
		color: #805B11;
	}

	.light-brown2{
		color: #B19120;
	}

	.caramelo{
		color: #AE7D17;
	}

	.dark-red{
		color: #94302D;
	}

	.light-brown,
	.light-brown a,
	.light-brown a:hover,
	.light-brown-hover:hover,
	.card:hover .light-brown-hover{
		color: #ac5300;
	}

	.imovel-descricao-color{
		color: #292a2e;
	}

	.red{
		color: #FF0000;
	}

	.dark-orange{
		color: #E54C1A;
	}

	.hover-orange:hover{
		color: #F48C24;
	}

	.hover-dark-orange:hover{
		color: #E54C1A;

		.icon.facebook{
			@extend .facebook-active;
		}

		.icon.instagran{
			@extend .instagran-active;
		}
	}

	.hover-white{
		color: FFFFFF;
	}

	.orange{
		color: #F29200;
	}

	.depoimento:after{
		content: "";
		display: block;
		width: 346px;
		height: 4px;
		margin-top:20px;
		position: absolute;
		left: calc( 50% - 173px);
		background-color: #111111;

	}
	.gold{
		color: #AC7D17;
	}

	.old-gold{
		color: #a37b17;
	}

	.old-gold:hover{
		color: #a37b17;
	}

	.green{
		color: #87C54D;
	}
//FIM BACKGROUNDS

//OVERFLOWS
	.overflow-hidden{
		overflow: hidden;
	}

	.overflow-auto{
		overflow: auto;
	}
//FIM OVERFOLWS

//DISPLAYS
	.lg-display-block{
		display: none;
	}
	.display-flex{
		display: flex;
	}

	.display-block{
		display: block;
	}

	.display-none{
		display: none;
	}

	.display-fixed{
		display: fixed;
	}

	.display-inline-block{
		display: inline-block;
	}

	.display-inline{
		display: inline;
	}

	.grow{
		flex-grow: 1;
	}
// FIM DISPLAY

//OVERFLOW
	.overflow-hidden{
		overflow:hidden;
	}

	.overflow-auto{
		overflow: auto;
	}
//FIM OVERFLOW

//Z-INDEX
	@for $i from 1 through 1000{
		.z-index#{$i}{
			z-index: #{$i};
		}
	}
//FIM Z-INDEX

//POSITIONS
	.static{
		position: static;
	}

	.absolute{
		position: absolute;
	}

	.relative{
		position: relative;
	}

	.fixed{
		position: fixed;
	}
//FIM POSITIONS

//JUSTIFYS
	.justify-between{
		justify-content: space-between;
	}

	.justify-center{
		justify-content: center;
	}

	.justify-left{
		justify-content: left;
	}

	.justify-right{
		justify-content: right;
	}

	.justify-flex-end{
		justify-content: flex-end;
	}

	.direction-column{
		flex-direction: column;
	}
//FIM JUSTIFYS

//TEXTS

	.menu-footer:hover{
		color: #AD7D17;
	}
	.alert{
		display: block!important;
	}
	.white-space-no-wrap{
		white-space: nowrap;
	}
	.list-none{
		list-style:none;
	}

	.imovel-itens{
		display: inline;
	}

	.blog-title:after{
		content: "";
	    width: 70px;
	    height: 2px;
	    background-color: #a37b17;
	    display: block;
	    margin-top: 20px;
	}

	.title-detalhe{
		width: 100%;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.title-detalhe:after{
		content: "";
		display: block;
		width: 100px;
		height: 5px;
		background-color: #88C54E;
		position: absolute;
		bottom: -3px;
	}

	.futuros .title-detalhe{
		width: 280px;
		height: 1px;
		background-color: #FFFFFF;
		margin: 0 auto;
		position: relative;
	}

	.futuros .title-detalhe:after{
		content:"";
		display: block;
		background-color: #333333;
		width: 80px;
		height: 4px;
		position: absolute;
		left: calc(50% - 40px);
		bottom: -2px;
	}

	.bg-parceiros .title-detalhe-parceiros{
		position: static;
	}

	.bg-parceiros .title-detalhe-parceiros:after{
		content:"";
		display: block;
		background-color: #333333;
		width: 166px;
		height: 4px;
		margin: 20px auto;
	}

	.novidades .title-detalhe{
		width: 270px;
		height: 1px;
		background-color: #AAAAAA;
		margin: 0 auto;
		position: relative;
	}

	.novidades .title-detalhe:after{
		content:"";
		display: block;
		background-color: #88C54E;
		width: 60px;
		height: 4px;
		position: absolute;
		left: calc(50% - 30px);
		bottom: -2px;
	}

	.transform-translateY{
		transform: translateY(50%);
	}

	.text-center.title-detalhe:after{
		left: calc(50% - 82px);
		width: 162px;
	}

	.section-title:after{
		content: "";
		display: block;
		width: 65px;
		height: 7px;
		background-color: #88C54E;
		margin: 10px auto 0 auto;
	}

	.title:after{
		content: "";
	    width: 77%;
	    height: 1px;
	    background-color: #000000;
	    display: block;
	    margin-top: auto;
	    margin-top: -15px;
	    right: 0;
	    position: absolute
	}

	.title-interna:after{
		content: "";
	    width: 236px;
	    height: 5px;
	    background-color: #88C54E;
	    display: block;	  
	    position: relative;
	    z-index: 100;
	    top: 3px;
	}

	.consultorio .title-left:after{
		margin-top: 0px!important;
	}

	.underline{
		text-decoration:underline;
	}

	.column2 {
	    -webkit-column-count:2; /* Chrome, Safari, Opera */
	    -moz-column-count:2;    /* Firefox */
	    column-count:2;         /* padrão */
	}
//FIM TEXTS

.detalhe-submenu:before{
	content: "";
	display: block;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-bottom: 24px solid #F29200;
	position: absolute;
	bottom: 100%;
	left: calc(50% - 25px);
}

.line-height3rem{
	line-height: 3rem;
}


//SLIDERS
	.carousel-indicators .active,
	.carousel-indicators li:hover{
		background-color: #F49D1C;
	}

	#carouselservicos .carousel-control-prev,
	#carouselservicos .carousel-control-next{
		width:23px;
	}

	//.icon.nav-prev,
	//.icon.arrow-left{
	//	@extend .fa,.fa-angle-left;
	//	color: #000000;
	//}

	//.icon.nav-prev:hover,
	//.icon.arrow-left:hover{
	//	@extend .fa,.fa-angle-left;
	//	color: #8d6910;
	//}

	//.icon.nav-next,
	//.icon.arrow-right{
	//	@extend .fa,.fa-angle-right;
	//	color: #000000;
	//}

	//.icon.nav-next:hover,
	//.icon.arrow-right:hover{
	//	@extend .fa,.fa-angle-right;
	//	color: #8d6910;
	//}
//FIM SLIDERS
.centraliza{
	transform: translateX(-50%);
	left: 50%;
}
//BOXES
	.item.active .box-cabecario{
		background-color: #AE7D17;
	}

	.item.active .fa-plus{
		@extend .fa-minus;
	}

	.item.active .question,
	.item.active .titulo,
	.item.active .plus{
		color: #FFFFFF;
	}

	.box-planos .titulo{
		
		margin-bottom:20px;
	}

	.box-planos .titulo:after{
		content: "";
		display: block;
		width: 100px;
		height: 2px;
		background-color: #F4E9C4;
		margin-top: 10px;

	}

	.box-planos:hover{
		border: #AE7D17 solid 4px;
	}

	.box-planos:hover .titulo:after{
		background-color: #AE7D17;
	}

	.detalhe-left:after{
		content:"";
		display:block;
		width: 100px;
		height: 2px;
		background-color: #F4E9C4;
		margin-top:10px;
	}

	.card-servicos{
  		transition: all 0.5s linear;
	}

	.card-servicos:hover{
		// margin-top: -15px;
		transform: scale(1.05);
		box-shadow: 0 0 8px rgba(#000,0.4)
	}

	.card-servicos:hover .servico-link{
		display: flex;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.card-servicos:hover .bg-ocre{
		background-color: #AE7D17;
		color: #FFFFFF;
	}

	.servico-link:after{
		content:"";
		display: block;
		width: 46px;
		height: 3px;
		background-color: #F4E9C4;
		position: absolute;
		top: calc(100% + 10px);
		left: calc(50% - 25px);
	}

	.card-empreendimento:hover .bg-gray-opacity{
		display: block;
	}

	.box:hover .bg-green-opacity{
		display: flex;
	}

	.card-empreendimento:hover .black{
		color: #88C54E;
	}

	.btn-border-grayD:hover{
		background-color: #a37b17;
		border: 0!important;
		color: #333333!important;
	}
	.item-categoria:hover .hover-old-gold{
		color: #a37b17;
	}

	.item-categoria:hover .bg-yellow-opacity{
		display: flex;
	}
	.bg-gray-opacity{
		background-color: rgba(#88C54E,.8);
	}
	.box-botoes{
	    left: calc(50% - 193px);
	    bottom:-70px;
	    position: absolute;
	}

	.barra-fixa{
		left:30px;
		z-index:998;
	}

	.produtos-area:hover .items-area{
		display: block;
	}

	.produtos-area:hover .hover-bg-dark-orange{
		background-color: #E54C1A;
	}

	.produtos-area:hover .hover-white{
		color: #FFFFFF;
	}

	.box-light-purple{
		max-width: calc(100% + 170px);
	}

	#redes-sociais,
	.logo{
		transition: all 0.5s linear;
	}
	.topo.fica-menor{
		box-shadow: 0 5px 10px #444;
	}

	.topo.fica-menor .logo{
		width: 75%;
	}
	.some-maluko{
		padding: 0;
		height: 0;
		overflow: hidden;
	}

	.card-atuacao:hover .mascara{
		display: flex;
	}

	.card-atuacao:hover .titulo,
	.card-atuacao:hover .descricao{
		color: #a37b17;
	}

	.card-produtos-hover:hover{
		color: #EF263F;
		border-bottom: 5px solid #000000;
	}
	.box-imagem:hover .bg-afrodescendente{
		display:flex!important;
	}

	.obras .thumb:hover .mascara{
		display: flex;
	}	
	.card-produtos a:hover{
		color: #5EA7AD;
	}
	.produtos-thumb .descricao{
		opacity: 0;
		transition: opacity 0.5s linear;
	}
	.produtos-thumb:hover .descricao{
		opacity: 1;
	}

	.box-shadow{
		box-shadow: 0 0 5px 2px #888;
	}

	.bg-box-black{
		background-color: rgba(0, 0, 0, .6);
	}

	.thumb:hover .bg-afrodescendente{
		display: block;
	}

@media (max-width: 517px){
	.sm-size12{
		font-size: 12px;
	}
	.box-botoes{
		bottom: -130px;
		
		& button{
			margin-bottom: 15px;
		}
	}
}
@media (min-width: 1200px){
	.menu-header:hover,
	ul .active a,
	ul li:hover a{
		background-color: #94302D;
		border-radius: 10px 10px 0 0;
	}

	ul li a:hover{
		color: #FFFFFF;
		text-decoration: none;
	}

	.box-banner{
		transform: translateY(-50%);
	}

	.menu-hamburguer,
	.logo-mobile{
		display: none !important;
	}

	.input-busca{
		max-width: 290px!important;
	}

	.menu-item{
		color: #FFFFFF;
		font-size: 16px;
		display: flex;
		position: relative;
		margin-top: -5px;
		justify-items: center;
		justify-content: center;
	}

	.logo-header{
		display: inline;
	}
}

@media (max-width: 1199px){
	.item-menu{
    	font-size: 16px!important;
  	}
  	.lg-margin-top0{
    	margin-top: 0!important;
  	}
  	
	.lg-display-block{
		display: block;
	}

	.lg-bg-brown{
		background-color: #805B11;
	}

	.lg-padding-right30{
		padding-right: 30px;
	}

	.lg-border-radius0{
		border-radius: 0;
	}

	.item-menu a{
		padding-top:5px;
		padding-bottom:5px;
		color: #444444;
		font-size: 15px;
	}

	.menu-header:hover,
	ul .active a,
	ul li:hover a{
		background-color: #FFFFFF;
		color: #94302D!important;
	}

	ul li a:hover{
		color: #FFFFFF;
		text-decoration: none;
	}
	
	.menu-item{
		color: #222222;
	}

	.logo-mobile{
		display: inline;
	}

	.logo-header{
		display: none;
	}

	.menu{
		padding-left: 0;
		padding-right: 0;
	}

	.lg-margin-top20{
		margin-top: 20px;
	}

	.lg-black{
		color: #000000!important;
	}

	.lg-text-left{
		text-align: left!important;
	}

	.lg-column{
		flex-direction: column;
	}

	.lg-static{
		position: static;
	}

	.menu ul li{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.topo-content{
		width: 250px;
		position: fixed;
		left: -250px;
		z-index: 999;
		transition: left 0.4s linear;
		height: 100%;
		background-color: #FFFFFF!important;
		border: 2px solid #88C54E;
		top: 0;
	}

	.topo-content.active{
		left:0;
	}
	.bg-menu,
	.bg-menu li{
		background-color: #FFFFFF !important;
	}

	.bg-menu li{
		border-bottom: 1px solid #DDDDDD;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.bg-menu li a{
		color: #333333 !important;
		font-family: nunito-bold;
		&:hover{
			color: #bc843f;
		}
	}

	.bg-menu li:hover a,
	.bg-menu .active a{
		color: #bc843f !important;
	}

	.barra-fixa{
		left:0;
	}
	
	.lg-margin-top30{
		margin-top: 30px;
	}

	.lg-text-center{
		text-align: center;
	}
	.menu-desktop{
		display: none;
	}
	.banner .absolute{
		position: static!important;
	}

	.bg-box-black{
		background-color: #000000!important;
		padding-left: 12.5%;
		padding-right: 12.5%;
	}

	.banner .absolute .container{
		padding-left: 0!important;
		padding-right: 0!important;
		width: 100%!important;
	}

	.img-contato{
		display: none;
	}

	.size50{
		font-size: 25px!important
	}

	.lg-text-center{
		text-align: center;
	}
}

	.container-fluid{
		max-width: 100%!important;
	}

	.card:hover{
		box-shadow: 0 0 5px #888888;
	}

	.sidebar ul{
		background-color: #FFFFFF;
		border-top:0;

		li:before{
			content: "-";
			position: absolute;
		    line-height: 40px;
		}
	}

	.sidebar .title{
		position: relative;
	}

	.sidebar .title:after{
		content: "";
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-top-color: #5c4026;
		left: 20px;
		top: 100%;
	}

	.card-logo{
		width: 150px;
		transition: width 0.3s linear;
	}

	.card-condominios:hover .card-logo{
		width:75px;
	}

	.card-condominios .descricao{
		opacity: 0;
		transition: opacity 0.3s linear;
	}

	.card-condominios:hover .descricao{
		opacity: 1;
	}
	.submenu-area{
	    left: 50%;
	    transform: translateX(-50%);
    	top: 100%;
    	display: none;
    	transform: display 0.3s linear;
	}

	.submenu-control:hover .submenu-area{
		display: block
	}

	.submenu-area:before{
		content: "";
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-bottom-color: #EEEEEE;
		left: calc(50% - 10px);
		bottom: 100%;
	}
	
	.submenu-item{
		& ~ .submenu-item{
			border-top: 1px solid #5EA7AD;
		}
	}

	.sidebar-item{
		& ~.sidebar-item{
			border-top: 1px solid #DDDDDD;
		}
	}

	.sidebar-item:hover a{
		color: #006CD8;
	}

	.bg-topo{
		position: fixed;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		display: none;
		z-index: 100;
	}

	.bg-topo.active{
		display: block;
	}

	.bg-carrinho-modal{
		background-image: url(../images/estrutural/bg-carrinho-modal.png);
		background-size: cover;
	}

	.modal-dialog{
		max-width: 100%!important;
	}
//FIM BOXES

//FONTS
	.italic{
		font-style: italic;
	}
	.font-style-normal{
		font-style: normal;
	}

	.hover-poppins-bold:hover{
		font-family: 'poppinsbold';
	}
//

//MENUS
/* menu desktop */

.menu-item{
	a:hover{
		color: #EA4C19!important;
	}
}

.submenus{
	transform: translateX(-50%);
	left: 50%;
}

.submenus a:hover{
	color: #003366;
}

.submenu-gray5{
	color: #555555!important;
}

.area-submenu:hover .submenus{
	display: block;
}

/*menu hamburguer*/
.logo-menu{
	padding-bottom: 15px;
}
.topo.menu-open .topo-content{
	left: 0;
	overflow: auto
}

.topo.menu-open {
	& ~ .bg-brown .topo-content{
		left: 0;
	}
}

.pop-up{
	display: none;

	&.active{
		display: block;
	}
}

.bg-afrodescendente{
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	z-index: 998;
	background: rgba(#000,0.8);
	display: none;
	&.active{
		display: block;
	}
}

.bg-popup{
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	z-index: 998;
	background: rgba(#004962,0.6);
	display: none;
	
	&.active{
		display: block;
	}
}

.bg-afrodescendente-popup{
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	z-index: 998;
	background: rgba(#000,0.8);
	display: none;
	&.active{
		display: block;
	}
}

.footer ul li:hover a{
	border-bottom: 0;
}



.sidebar.active{
	left: 0;
}

//FIM MENUS

//CAROUSEL
	.empreendimentos .carousel-control-prev,
	.empreendimentos .carousel-control-next{
		width:16px;
	}

	.empreendimentos .carousel-control-prev{
		left: -20px;
	}

	.empreendimentos .carousel-control-next{
		right: -20px;
	}

	.futuros .carousel-control-prev,
	.futuros .carousel-control-next{
		width: 25px;
	}

	.futuros .carousel-control-prev{
		left: -20px;
	}

	.futuros .carousel-control-next{
		right: -20px;
	}

	.novidades .carousel-control-prev,
	.novidades .carousel-control-next{
		width:20px;
	}

	.novidades .carousel-control-prev{
		left: -20px;
	}

	.novidades .carousel-control-next{
		right: -20px;
	}

	.novidades .box:hover .poppins-bold,
	.novidades .box:hover .poppins-bold a{
		color: #88C54E!important;
	}

	.banner .carousel-control-prev,
	.banner .carousel-control-next{
		width:41px;
	}

	.produtos .carousel-control-prev{
		left: calc(100% - 60px);
	}

	.produtos .carousel-control-next{
		right: 0%;
	}
	#produtos .carousel-control-prev{
		left: -15px;
	}

	#produtos .carousel-control-next{
		right: -15px;
	}
//FIM CAROUSEL

@media (max-width:990px){
	.lg-display-block{
		display: block;
	}
	.lg-bg-brown{
		background-color: #805B11;
	}
	.lg-padding-right30{
		padding-right: 30px;
	}
	.lg-border-radius0{
		border-radius: 0;
	}
	.column2{
		-webkit-column-count:1; /* Chrome, Safari, Opera */
	    -moz-column-count:1;    /* Firefox */
	    column-count:1;         /* padrão */
	}

	md-paddding-side15{
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.md-bg-gray2{
		background-color: #222222;
	}
	
	.menu-item{
		padding-left: 20px;
		padding-right: 20px;
	}

	.md-padding0{
		padding: 0;
	}

	.md-colum{
		flex-direction: column;
	}

	.md-justify-center{
		justify-content: center;
	}

	.box-light-purple{
		max-width: 100%;
	}
	.md-margin-top30{
		margin-top: 30px;
	}

	.md-margin-left0{
		margin-left: 0;
	}
	.md-margin-auto{
		margin-right: auto;
		margin-left: auto;
	}

	.md-margin-top15{
		margin-top: 15px;
	}

	.md-justify-center{
		justify-content: center!important;
	}

	.md-margin-side5{
		margin-left:5px;
		margin-right:5px;
	}

	.title:after{
		display: none;
	}

	#carouselProdutosNovidades .carousel-control-prev,
	#carouselProdutosNovidades .carousel-control-next,
	.produtos .carousel-control-prev,
	.produtos .carousel-control-next{
		bottom: calc(100% + 40px);
	}

	.md-text-left{
		text-align: left!important;
	}

	.md-text-center{
		text-align: center!important;
	}

	.md-static{
		position: static!important;
	}

	.md-display-block{
		display: block;
	}

	.md-display-none{
		display: none;
	}

	.md-opacity1{
		opacity: 1!important;
	}

	.md-card-logo{
		width: 75px;
	}

	.categorias-menu{
		display: inline;
	}
	.btn-fechar{
		display: block;
	}

	.btn-busca{
		position: static!important;
	}
}

@media(max-width: 768px){
	.sm-margin-bottom30{
		margin-bottom: 30px;
	}
	
	.sm-size50{
		font-size: 50px;
	}

	.sm-size35{
		font-size: 35px;
	}
	.sm-display-none{
		display: none;
	}

	.sm-padding-side15{
		padding-left:15px;
		padding-right: 15px;
	}

	.sm-margin-top30{
		margin-top: 30px;
	}
	.sm-text-center{
		text-align: center!important;
	}
	.sm-display-none{
		display: none;
	}
	//
	//.container{
		//max-width:100%!important;
	//}

	.imovel-itens{
		display: block;
	}

	.text-left .paginacao{
		text-align: center!important;
	}

	.sm-display-block{
		display: block;
	}

	.sm-display-none{
		display: none;
	}
}

@include media-breakpoint-down(md){
	.container{
		max-width: 97%;
	}
}

@include media-breakpoint-down(sm){
	.container{
		max-width: 97%;
	}
}