.footer{
	.bg-ocre{
		@include gradient-y(#FFF, rgba(#F4E9C4, 1),0% , 52px);
	}

	.bg-golden{
		padding:10px 0;
		background: #AE7D17;
		color: #FFF;
		font-family: 'open_sanslight';

		.fa-mobile{
			width: 26px;
			height: 26px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background:#F4E9C4;
			color: #805B11;
			font-size: 21px;
			line-height: 1;
		}
	}

	.copyrights{
		font-size: 11px;
	}

	.media{
		.icon-area{
			min-width: 40px;
		}
	}
}


.contrate-footer{
	margin: 0 7px;
}

@include media-breakpoint-up(xl){
	.footer{
		padding-bottom: 75px;

		.bg-golden{
			position: fixed;
			width: 100%;
			z-index:997;
			left:0;
			bottom:0;
		}
	}
}

.cell-footer{
	width: 30px;
	height: 30px;
	line-height: 26px;
	border:2px solid #FFF;
	color: #AE7D17;
	text-align: center;
	border-radius: 100%;
	font-size: 20px;
}