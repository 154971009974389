html,
body{
	width: 		100%;
	min-height: 100vh;
}

#app{
	display: 		flex;
	flex-direction: column;
	width:100%;
	min-height: 	100vh;
	overflow: hidden;

	& > .wrapper{
		flex-grow: 1;
	}
}