@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light/opensans-light_0-webfont.eot');
    src: url('../fonts/opensans-light/opensans-light_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light/opensans-light_0-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light/opensans-light_0-webfont.woff') format('woff'),
         url('../fonts/opensans-light/opensans-light_0-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light/opensans-light_0-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans_regular/opensans-regular-webfont.eot');
    src: url('../fonts/opensans_regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans_regular/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans_regular/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans_regular/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans_regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/Poppins-Regular/poppins-regular-webfont.eot');
    src: url('../fonts/Poppins-Regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Poppins-Regular/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/Poppins-Regular/poppins-regular-webfont.woff') format('woff'),
         url('../fonts/Poppins-Regular/poppins-regular-webfont.ttf') format('truetype'),
         url('../fonts/Poppins-Regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/Poppins-Medium/poppins-medium-webfont.eot');
    src: url('../fonts/Poppins-Medium/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Poppins-Medium/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/Poppins-Medium/poppins-medium-webfont.woff') format('woff'),
         url('../fonts/Poppins-Medium/poppins-medium-webfont.ttf') format('truetype'),
         url('../fonts/Poppins-Medium/poppins-medium-webfont.svg#poppinsmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans-bold/opensans-bold-webfont.eot');
    src: url('../fonts/opensans-bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-bold/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans-bold/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-bold/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/Poppins-Bold/poppins-webfont.eot');
    src: url('../fonts/Poppins-Bold/poppins-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Poppins-Bold/poppins-webfont.woff2') format('woff2'),
         url('../fonts/Poppins-Bold/poppins-webfont.woff') format('woff'),
         url('../fonts/Poppins-Bold/poppins-webfont.ttf') format('truetype'),
         url('../fonts/Poppins-Bold/poppins-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextrabold';
    src: url('../fonts/popins-extrabold/poppins-extrabold-webfont.eot');
    src: url('../fonts/popins-extrabold/poppins-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/popins-extrabold/poppins-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/popins-extrabold/poppins-extrabold-webfont.woff') format('woff'),
         url('../fonts/popins-extrabold/poppins-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/popins-extrabold/poppins-extrabold-webfont.svg#poppinsextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gothic';
    src: url('../fonts/CenturyGothic/Century Gothic.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/Montserrat-Regular/montserrat-regular-webfont.eot');
    src: url('../fonts/Montserrat-Regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat-Regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/Montserrat-Regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/Montserrat-Regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/Montserrat-Regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/Montserrat-Bold/montserrat-bold-webfont.eot');
    src: url('../fonts/Montserrat-Bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat-Bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/Montserrat-Bold/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/Montserrat-Bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/Montserrat-Bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratblack';
    src: url('../fonts/Montserrat-Black/montserrat-black-webfont.eot');
    src: url('../fonts/Montserrat-Black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat-Black/montserrat-black-webfont.woff2') format('woff2'),
         url('../fonts/Montserrat-Black/montserrat-black-webfont.woff') format('woff'),
         url('../fonts/Montserrat-Black/montserrat-black-webfont.ttf') format('truetype'),
         url('../fonts/Montserrat-Black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/Century-Gothic-Bold/CenturyGothic-Bold.eot');
    src: url('../fonts/Century-Gothic-Bold/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Century-Gothic-Bold/CenturyGothic-Bold.woff2') format('woff2'),
        url('../fonts/Century-Gothic-Bold/CenturyGothic-Bold.woff') format('woff'),
        url('../fonts/Century-Gothic-Bold/CenturyGothic-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
