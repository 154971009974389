a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.montserrat{
    font-family: 'montserratregular';
}

.montserrat-bold{
	font-family: 'montserratbold';
}

.montserrat-black{
	font-family: 'montserratblack';
}

.opensans{
	font-family: 'open_sansregular';
}

.opensans-bold{
	font-family: 'open_sansbold';
}

.opensans-light{
    font-family: 'open_sanslight';
}

.gothic{
	font-family: 'gothic';
}

.gothic-bold{
    font-family: 'Century Gothic';
}

.poppins{
	font-family: 'poppinsregular';
}

.poppins-medium{
	font-family: 'poppinsmedium';
}

.poppins-bold{
	font-family: 'poppinsbold';
}

.poppins-extrabold{
	font-family: 'poppins-extrabold';
}


.page-header{
	padding-top: 60px;
	padding-bottom: 60px;
	text-align:center;
	background: #F9E7C8;

	h1{
		font-family: 'poppinsextrabold';
		color: #9E2D32;
	}

	&.obtuario-header{
		min-height: 300px;
		display: flex;
		align-items: center;
		background: url(#{$imgs}/bg-obituarios.jpg) center center no-repeat;
		background-size: cover;
	}
}

